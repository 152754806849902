export interface IState {
	logotype: ILogotype
	menu: IMenuItem[]
	startPage: object
	showMenu: boolean
	showSearch: boolean
	isMobile: boolean
	searchPageUri: string
	translationLink: ITranslationLink
	endpoints: {
		getChildren: string
		getMenu: string
		getBreadcrumbs: string
	}
	menuBreadcrumbs: IMenuItem[]
	menuHeight: number
}

export interface ILogotype {
	uri: string
	altText: string
}

export interface ITranslationLink {
	name: string
	uri: string
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

const defultState: IState = {
	logotype: {
		uri: '',
		altText: ''
	},
	menu: [],
	startPage: {},
	showMenu: false,
	showSearch: false,
	isMobile: false,
	searchPageUri: '',
	translationLink: {
		name: '',
		uri: ''
	},
	endpoints: {
		getChildren: '',
		getMenu: '',
		getBreadcrumbs: ''
	},
	menuBreadcrumbs: [],
	menuHeight: 0
}

export default defultState;
