import { MutationTree, Mutation } from 'vuex';
import { IMenuItem, IState } from './state';
import { ActionPayload } from './actions';

export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setMenuMutation: MutationHandler<IState> = (state, {payload}) => {
	Object.assign(state, {menu: payload})
}

const showMenuMutation: MutationHandler<boolean> = (state, {payload}) => {
	Object.assign(state, {showMenu: payload})
}

const checkMobileMutation: MutationHandler<number> = (state, { payload }) => {
	Object.assign(state, {
		isMobile: payload < 768
	})
}

const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}

const expandMenuItemMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded, isCurrent: !payload.isCurrent});
	state.menuBreadcrumbs.push(payload)
}

const deactivateCurrentItemMutation: MutationHandler<undefined> = (state, { payload }) => {
	const lastItem = state.menuBreadcrumbs.length - 1;
	Object.assign(state.menuBreadcrumbs[lastItem], {isExpanded: !state.menuBreadcrumbs[lastItem].isExpanded})
	state.menuBreadcrumbs.pop()
}

const toggleSearchMutation: MutationHandler<boolean> = (state, { payload }) => {
	Object.assign(state, {showSearch: payload})
}

const setMenuHeightMutation: MutationHandler<number> = (state, { payload }) => {
	Object.assign(state, {menuHeight: payload})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setMenu: setMenuMutation,
	showMenu: showMenuMutation,
	checkMobile: checkMobileMutation,
	addMenuItems: addMenuItemsMutation,
	expandMenuItem: expandMenuItemMutation,
	deactivateCurrentItem: deactivateCurrentItemMutation,
	toggleSearch: toggleSearchMutation,
	setMenuHeight: setMenuHeightMutation
}

export default mutations
