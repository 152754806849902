
import Vue from 'vue'

interface SearchFormData {
  query: string
}

interface SearchFormComputed {
  showSearch: boolean
  searchPageUri: string
  isMobile: boolean
}

interface SearchFormMethods {
  clearQuery(event: Event): void
}

export default Vue.extend<{}, SearchFormMethods, SearchFormComputed, SearchFormData>({
	name: 'SearchForm',
  data() {
    return {
      query: ''
    }
  },
  computed: {
    showSearch() {
      return this.$store.state.showSearch;
    },
    searchPageUri() {
      return this.$store.state.searchPageUri;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  methods: {
    clearQuery() {
      this.$data.query = '';
    }
  }
})
